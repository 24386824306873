<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	data:{data:false},
            	heroImage:{},
            	staticContent:{},
            	filter: {skip:0,years:'all',month:''},
                range_months:[],
                year_dm:[],
            }
        },
        computed:{
			myParams(){
				var query = Gen.clone(this.$route.query)
				if(this.$route.query.tag) query.tag = this.$route.query.tag
				return query
			}
		},
        async mounted() {
        	await this.ready();
        		Vue.component("Header",()=>import("@frontend/part/Header.vue"))
            this.$set(this.$root, 'page', this);
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetCustomerActivitiesList()
            this.apiGetCustomerStatic()
            this.apiGetHero()
            $('[data-toggle="tooltip"]').tooltip();
        },
        methods: {
        	apiGetCustomerStatic(){
        		Gen.apirest('/static-customer', {}, (err, resp)=>{
	              	if(err) console.log(err)
	              	this.staticContent=resp.data.content
	          	})
        	},
      	 	apiGetHero(){
              	Gen.apirest('/hero/2', {}, (err, resp)=>{
                	if(err) console.log(err)
                  	this.heroImage=resp.data
              	})
          	},
        	apiGetCustomerActivitiesList(callBack=null){
        		this.loadingOverlay = false
        		Gen.apirest('/customer-activities', this.myParams, (err, resp)=>{
        			this.loadingOverlay = false
              		if(err) console.log(err)
              		if(callBack) return callBack(resp)
              		this.data=resp.data
              		this.year_dm=resp.year_dm
                    this.range_months=resp.range_months
                    this.$root.topProgress.done()

                    // Autoselect
                    if(resp.select_year){
                        this.filter.years = resp.select_year
                        this.filter.month = resp.select_month
                    }
                    Gen.apirest("/customer-get-month", {years:this.filter.years}, (err,resp)=>{
                        this.range_months = resp
                    }, "GET");
	          	})
        	},
        	onPaging(page = 1) {
		      	if ($(".page-item.active").text() == page) return;
		      	var query = Object.assign(Gen.clone(this.$route.query), {
		        	page: page
		      	})
		      	this.$router.push({
		        	name: this.$route.name,
		        	query: query
		      	})
		    },
		    monthFilter(v){
                if(!v) return;
                this.filter.month = v
            }
        },
        watch:{
        	"$route.params"(v){
        	 	console.log(v)
        	},
        	$route() {
        		this.apiGetCustomerActivitiesList()
        		setTimeout(()=>{ $("html,body").scrollTop(550) }, 100)
        	},
        	'filter.years'(v){
        		Gen.apirest("/customer-get-month", {years:v}, (err,resp)=>{
                    this.range_months = resp
                }, "GET");

                this.$router.push({name:'CustomerActivities',query:{years:v}})
            }
        }
    };
</script>
<template>
  	<div Content>
  		<Header></Header>
	     	<section id="slider" class="activities slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
	     		<!-- slider-parallax-inner -->
				<div class="paralaxActivities">
					<div class="swiper-container swiper-parent swiper-container-activities">
						<div v-if="!isMobile" class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image, '1349x350')+'\'); background-size: cover; background-position: top;'">
							<div class="container clearfix">
								<div class="slider-caption slider-caption-activities dark go-mid">
									<h3>{{heroImage.mhi_title}}</h3>
									<p class="mt-2">{{heroImage.mhi_desc}}</p>
								</div>
							</div>
							<div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
						</div>
						<div v-else class="swiper-wrapper bg-x-33" :style="'background-image: url(\''+uploader(heroImage.mhi_image, '360x240')+'\'); background-size: cover; background-position: top;'">
							<div class="container clearfix">
								<div class="slider-caption slider-caption-activities dark go-mid">
									<h3>{{heroImage.mhi_title}}</h3>
									<p class="mt-2">{{heroImage.mhi_desc}}</p>
								</div>
							</div>
							<div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
						</div>
					</div>
					<a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
				</div>
			</section>
			<section id="page-title" class="page-title-mini">
				<div class="container clearfix">
					<h1 class="d-none d-md-block">&nbsp;</h1>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
						<li class="breadcrumb-item active" aria-current="page">{{ web.mn_customer_act }}</li>
					</ol>
				</div>
			</section>
			<section id="content" class="overflow-none">
				<div class="content-wrap">
					<section class="section nobg nobottommargin notopmargin ">
						<div class="container">
							<div class="row">
								<div class="col-md-12">
									<blockquote class="blockquote1">
										<div v-html="staticContent.msc_desc"></div>
									</blockquote>
								</div>
							</div>
						</div>
					</section>
					<section class="section nobottommargin notopmargin">
						<div class="container">

							<!-- Filter Mobile -->
							<div class="row d-md-none mb-3">
								<div class="col-md-3">
	                                <div class="wrap_filter_achievment">
	                                    <VForm method="get">
	                                    <h3>Filter</h3>
	                                    <div class="form-row mb-4 justify-content-end">
	                                        <div class="col-md-12">
	                                            <select v-model="filter.years" name="years" class="form-control">
	                                                <option :value="'all'">Semua Tahun</option>
	                                                <option v-for="(v1,k1) in year_dm" :value="v1.ppr_year" v-html="v1.ppr_year"></option>
	                                            </select>
	                                        </div>
	                                        <div class="col-md-12" v-if="filter.years!='all'">
	                                            <ul>
	                                                <li><router-link :class="filter.month == 'all' ? 'active_filter' : '' "   @click="monthFilter('all')" :to="{name:'CustomerActivities',query:{years:filter.years}}">Semua Bulan <i class="icon-angle-right"></i></router-link></li>

	                                                <li v-for="(v2,k2) in range_months"  @click="monthFilter(v2.key)">
	                                                    <router-link :class="v2.key == filter.month ? 'active_filter' : '' " :to="{name:'CustomerActivities',query:{years:filter.years,month:v2.key}}">{{v2['value']}} <i class="icon-angle-right"></i></router-link>
	                                                </li>
	                                            </ul>
	                                        </div>
	                                    </div>
	                                    </VForm>
	                                </div>
	                            </div>
							</div>

							<div class="row">
	                            <div class="col-md-9">
	                            	<div class="row justify-content-center">
										<div v-if="data.data.length" class="col-sm-6 col-lg-6 col-md-6" v-for="(v,k) in data.data">
											<div class="front-news">
												<div class="fn-img">
													<router-link :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">
														<VImg v-if="!isMobile" :src="uploader(v.ap_photo,'450x325')" :alt="v.ap_title"
															:title="v.ap_title"></VImg>
														<VImg v-else :src="uploader(v.ap_photo,'330x219')" :alt="v.ap_title"
															:title="v.ap_title"></VImg>
													</router-link>
												</div>
												<div class="fn-desc min_height_132">
													<h4 class="fnd-title" v-if="v.length_title>'6'">
														<router-link :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}" v-tooltip="v.ap_title">{{v.ap_title.limitWord(6)}}</router-link>
													</h4>
													<h4 class="fnd-title" v-else>
														<router-link :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">
															{{ v.ap_title }}</router-link>
													</h4>
													<h5 class="fnd-name" v-if="v.length_name>'15'" v-tooltip="v.ap_author_name">
														<i class="icon-user21"></i>
														{{v.ap_author_name.limitChar(15)}}
													</h5>
													<h5 class="fnd-name" v-else>
														<i class="icon-user21"></i>
														{{v.ap_author_name}}
													</h5>
													<span class="fnd-date">
														<i class="icon-calendar21"></i>
														{{(v.ap_publish_date).dates('format')}}
													</span>
												</div>
											</div>
										</div>
										<div v-if="!data.data.length" class="col-sm-12 col-lg-12 col-md-12">
		                                    <h3 class="text-center">Tidak ada data</h3>
		                                </div>
									</div>
									<Pagination v-if="data.length" :data="data" :limit="9" @pagination-change-page="onPaging"></Pagination>
								</div>
								<div class="col-md-3 d-none d-md-block">
	                                <div class="wrap_filter_achievment">
	                                    <VForm method="get">
	                                    <h3>Filter</h3>
	                                    <div class="form-row mb-4 justify-content-end">
	                                        <div class="col-md-12">
	                                            <select v-model="filter.years" name="years" class="form-control">
	                                                <option :value="'all'">Semua Tahun</option>
	                                                <option v-for="(v1,k1) in year_dm" :value="v1.ppr_year" v-html="v1.ppr_year"></option>
	                                            </select>
	                                        </div>
	                                        <div class="col-md-12" v-if="filter.years!='all'">
	                                            <ul>
	                                                <li><router-link :class="filter.month == 'all' ? 'active_filter' : '' "   @click="monthFilter('all')" :to="{name:'CustomerActivities',query:{years:filter.years}}">Semua Bulan <i class="icon-angle-right"></i></router-link></li>

	                                                <li v-for="(v2,k2) in range_months"  @click="monthFilter(v2.key)">
	                                                    <router-link :class="v2.key == filter.month ? 'active_filter' : '' " :to="{name:'CustomerActivities',query:{years:filter.years,month:v2.key}}">{{v2['value']}} <i class="icon-angle-right"></i></router-link>
	                                                </li>
	                                            </ul>
	                                        </div>
	                                    </div>
	                                    </VForm>
	                                </div>
	                            </div>
							</div>
						</div>
					</section>
				</div>
			</section>
  	</div>
</template>